import { motion } from "framer-motion";
import React from "react";
import SectionHeading from "../../Components/SectionHeading";
import ImageBox from "./ImageBox";
import Numbers from "./Numbers";

const WhatWeDoing = () => {
  const animations = {
    content: {
      initial: { opacity: 0, y: -30 },
      exit: { opacity: 0, y: -20, transition: { duration: 0.5 } },
      animate: { opacity: 1, y: 0, transition: { duration: 0.4, delay: 1.5 } },
    },
  };

  // const paragraphContent = `
  //   we strongly believe that every action can have a positive reaction and that every change that we imbibe, however miniscule will generate its own positive impact in the future.
  //   This is why we have devised our new age biodegradable and compostable line of tableware is envisioned to create a shroud of much needed green today, for the future to reap tomorrow.
  //   Our compostable and biodegradable line of tableware is made of 100% natural eco friendly pulp. Eco friendly is the chosen ingredient in our success story of the future because it is a non-food renewable resource which yields a new harvest year after year for generations to come.
  //   WAfter the eco friendly stalk has been processed for its primary ingredient; Eco friendly, there is a plant fibre that stays behind as a remnant.
  // `;
  // const paragraphs = paragraphContent.split("\n").map((paragraph, index) => (
  //   <p key={index}>{paragraph}</p>
  // ));

  return (
    <>
      <div className="flex flex-col items-center mx-auto text-center overflow-hidden">
        <SectionHeading heading="This Is What We Are Doing?" />
        <motion.p viewport={{ once: true }} variants={animations.content} initial="initial" animate="animate" exit="exit" >
          {/* We Have Our Expansion Planning Also We Provide CNC Machine Guard,
          Electric Panel etc With Best services. Our primary product is sheet metal work an we will be providing for lifetime our primary product our primary product is used in making boxes etc. Our company works with jyoti CNC group. We do not tailor to niche market and we have quick tern ability. We do not have a single percentage in our business for repeat business, we have unique product what we provide to our customer we have domestic and international customer who works with us from last so many years. Right now our company is working with Indian company. We have started to deal in international market also we have specialized equipment & manufacturing process in our company. */}
          {/* {paragraphs} */}

          <section className="text-gray-600 body-font overflow-hidden">
            <div className="container px-48 py-4 mx-auto">
             
              <div className="flex flex-wrap -m-4 text-left">
                <div className="xl:w-1/2 md:w-full w-96 p-4">
                  <div className="border border-gray-200 p-6 rounded-lg">
                    <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-[#FFE300] text-indigo-500 mb-4">
                      <img src="https://img.icons8.com/ios/50/000000/export.png" className="w-6 h-6" alt="img"/>
                    </div>
                    <h2 className="text-2xl text-gray-900 font-medium title-font mb-2">Export</h2>
                    <ul className="leading-relaxed text-base">
                      <li> Our commitment to environmental sustainability extends globally through eco-friendly exports.</li>  
                      <li> We proudly share green innovations and products worldwide, contributing to a greener planet one international shipment at a time.</li>  
                    </ul>
                    {/* <p className="leading-relaxed text-base">Fingerstache flexitarian street art 8-bit waist co, subway tile poke farm.</p> */}
                  </div>
                </div>
                <div className="xl:w-1/2 md:w-full w-96 p-4">
                  <div className="border border-gray-200 p-6 rounded-lg">
                    <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-[#FFE300] text-indigo-500 mb-4">
                     <img src="https://img.icons8.com/ios/50/000000/import.png" className="w-6 h-6" alt="img"/>
                    </div>
                    <h2 className="text-2xl text-gray-900 font-medium title-font mb-2">Import </h2>
                    <ul className="leading-relaxed text-base">
                      <li>We specialize in importing eco-friendly products, carefully sourced for sustainability and quality.</li>
                      <li> Our mission is to bring these green solutions to your doorstep, making eco-conscious living more accessible.</li>
                    </ul>
                  </div>
                </div>
                <div className="xl:w-1/2 md:w-full w-96 p-4">
                  <div className="border border-gray-200 p-6 rounded-lg">
                    <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-[#FFE300] text-indigo-500 mb-4">
                     <img src="https://img.icons8.com/ios/50/000000/warehouse.png" className="w-6 h-6" alt="img"/>
                    </div>
                    <h2 className="text-2xl text-gray-900 font-medium title-font mb-2">Sourcing Agent</h2>
                    <ul className="leading-relaxed text-base">
                      <li>As your eco-friendly sourcing agent, we meticulously identify and secure sustainable products for your needs.</li>
                      <li>Our expertise ensures ethical and green sourcing, aligning your business with eco-conscious values.</li>
                    </ul>
                  </div>
                </div>
                <div className="xl:w-1/2 md:w-full w-96 p-4">
                  <div className="border border-gray-200 p-6 rounded-lg">
                    <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-[#FFE300] text-indigo-500 mb-4">
                     <img src="https://img.icons8.com/ios/50/000000/consultation.png" className="w-6 h-6" alt="img"/> 
                    </div>
                    <h2 className="text-2xl text-gray-900 font-medium title-font mb-2">Our Aim</h2>
                    <ul className="leading-relaxed text-base">
                      <li> we strongly believe that every action can have a positive reaction and that every change that we imbibe, however miniscule will generate its own positive impact in the future.</li>
                      {/* <li> From materials to leadership, we guide your journey towards sustainability, fostering positive change for your organization.</li> */}
                    </ul>
                  </div>
                </div>
            </div>
          </div>
          </section>
        </motion.p>



        <ImageBox />
        <Numbers />
      </div>
    </>
  );
};

export default WhatWeDoing;
