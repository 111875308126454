import SectionHeading from "../../Components/SectionHeading";
// import workers from "../../Assets/workers2.jpg";
// import weldingPic from "../../Assets/welding.jpg";
import ContentBox from "./Components/ContentBox";
import ImagesBox from "./Components/ImagesBox";
import cont from "../../Assets/container1.jpeg";
import cont2 from "../../Assets/container2.jpeg";
// import cont3 from "../../Assets/container3.jpeg";

const AboutCompany = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <SectionHeading heading="About Ganesh International Trade" />
        <div className="flex flex-row items-center justify-center pt-3 pb-20 md:pt-10">
          <ContentBox />
          <div className="flex-col items-center justify-center hidden lg:flex">
            <ImagesBox image={cont} />
            <ImagesBox image={cont2} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
