import React from "react";
// import MapPicker from "react-google-map-picker";

// const DefaultLocation = { lat: 23.038237, lng: 72.643741 };
// const DefaultZoom = 17;

export default function GoogleMap() {
  // const [defaultLocation] = useState(DefaultLocation);

  // const [setLocation] = useState(defaultLocation);
  // const [zoom, setZoom] = useState(DefaultZoom);

  // function handleChangeLocation(lat, lng) {
  //   setLocation({ lat: lat, lng: lng });
  // }

  // function handleChangeZoom(newZoom) {
  //   setZoom(newZoom);
  // }

  // function handleResetLocation() {
  //   setDefaultLocation({ ...DefaultLocation });
  //   setZoom(DefaultZoom);
  // }

  return (
    <>
      {/* <MapPicker defaultLocation={defaultLocation} zoom={zoom} onChangeLocation={handleChangeLocation} onChangeZoom={handleChangeZoom} apiKey="AIzaSyAkBhTU6Tc8FNdu64ZRG4rPm2bin7H7OOI" /> */}
      <iframe className="w-full"
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.612240837162!2d72.63894477846554!3d23.038005412047823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e86edc4982af5%3A0x5baf618dfe04a1c0!2sSARDAR%20PATEL%20MALL%2C%20Nikol%20Gam%20Rd%2C%20Suradhara%20Society%2C%20Thakkarbapanagar%2C%20Khodiar%20Nagar%2C%20Ahmedabad%2C%20Gujarat%20382350!5e0!3m2!1sen!2sin!4v1694861286034!5m2!1sen!2sin"
        width="2400"
        height="600"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
}

