import eco from './../Assets/productImgs/eco-friendly.jpg';
import t1 from './../Assets/greenplant.png';
import t2 from './../Assets/Greenfood.png';
import t3 from './../Assets/GreenFreezer.png';
import t4 from './../Assets/GreenMicrowave.png';
import t5 from './../Assets/GreenWater.png';
import cb6 from './../Assets/productImgs/Clamshell Container/6_6clamshellbox.webp';
import cc340 from './../Assets/productImgs/Clamshell Container/340mlclamshellContainer.webp';
// import ccf340 from './../Assets/productImgs/Clamshell Container/340MLclamshellContainerFood.webp';
import cc500 from './../Assets/productImgs/Clamshell Container/500MlclamshellContainer.webp';
// import ccf500 from './../Assets/productImgs/Clamshell Container/500MLclamshellContainerFood.webp';
import rc500 from './../Assets/productImgs/Clamshell Container/500MlRectangularContainer.webp';
import cb600 from './../Assets/productImgs/Clamshell Container/600MLclamshellbox.webp';
import sbbc from './../Assets/productImgs/Clamshell Container/SmallBioBoxclamshellbox.webp';
import oz12bowl from './../Assets/productImgs/Bowls/12ozbowlfood.webp';
// import oz12cup from './../Assets/productImgs/Bowls/12ozcup.webp';
import cup50 from './../Assets/productImgs/Bowls/50MLcup.webp';
// import cupfood50 from './../Assets/productImgs/Bowls/50MLcupfood.webp';
import cup100 from './../Assets/productImgs/Bowls/100MLcup.webp';
// import cupfood100 from './../Assets/productImgs/Bowls/100MLcupfood.webp';
import cup180 from './../Assets/productImgs/Bowls/180MLcup.webp';
// import cupfood180 from './../Assets/productImgs/Bowls/180MLcupfood.webp';
import cup240 from './../Assets/productImgs/Bowls/240MLcup.webp';
// import cupfood240 from './../Assets/productImgs/Bowls/240MLcupfood.webp';
import sbc from './../Assets/productImgs/Bowls/squarebowlcup.webp';
// import scf from './../Assets/productImgs/Bowls/squarecupfood.webp';
import st1 from './../Assets/productImgs/Meal Tray/1DSquareTray.webp';
// import stf1 from './../Assets/productImgs/Meal Tray/1DSquareTrayFood.webp';
import rt2 from './../Assets/productImgs/Meal Tray/2DRectangularTray.webp';
import mt5 from './../Assets/productImgs/Meal Tray/5CpMealTray.webp';
// import mtf5 from './../Assets/productImgs/Meal Tray/5CpMealTrayFood.webp';
import smt5 from './../Assets/productImgs/Meal Tray/5CpSchoolMealTray.webp';
// import smtf5 from './../Assets/productImgs/Meal Tray/5CpSchoolMealTrayFood.webp';
import cmt6 from './../Assets/productImgs/Meal Tray/6ComMealTray.webp';
// import ps6 from './../Assets/productImgs/Paper Straw/6MMPaperStraw.webp';
import spc6 from './../Assets/productImgs/Paper Straw/6MmStrawpaperColour.webp';
// import ps8 from './../Assets/productImgs/Paper Straw/8MmPaperStraw.webp';
import psc8 from './../Assets/productImgs/Paper Straw/8MmPaperStrawColour.webp';
// import ps10 from './../Assets/productImgs/Paper Straw/10MmPaperStraw.webp';
import psc10 from './../Assets/productImgs/Paper Straw/10MmPaperStrawColour.webp';
import iwps from './../Assets/productImgs/Paper Straw/IndividualWrapPaperStraw.webp';
import rp6 from './../Assets/productImgs/Round Plate/6_RoundPlate.webp';
// import rpf6 from './../Assets/productImgs/Round Plate/6_RoundplateFood.webp';
import rp7 from './../Assets/productImgs/Round Plate/7_RoundPlate.webp';
// import rpf7 from './../Assets/productImgs/Round Plate/7_RoundPlateFood.webp';
import crp7 from './../Assets/productImgs/Round Plate/7ComRoundPlate.webp';
import crp9 from './../Assets/productImgs/Round Plate/9_-3ComRoundPlate.webp';
// import crpf9 from './../Assets/productImgs/Round Plate/9_-3ComRoundPlateFood.webp';
import rp9 from './../Assets/productImgs/Round Plate/9ComRoundPlate.webp';
import crp10 from './../Assets/productImgs/Round Plate/10_-3ComRoundPlate.webp';
// import crpf10 from './../Assets/productImgs/Round Plate/10_-3ComRoundPlateFood.webp';
import rp10 from './../Assets/productImgs/Round Plate/10_RoundPlate.webp';
// import rpf10 from './../Assets/productImgs/Round Plate/10_RoundPlateFood.webp';
import crp11 from './../Assets/productImgs/Round Plate/11_-4ComRoundPlate.webp';
// import crpf11 from './../Assets/productImgs/Round Plate/11_-4ComRoundPlateFood.webp';
import crp12 from './../Assets/productImgs/Round Plate/12_-4ComRoundPlate.webp';
// import crpf12 from './../Assets/productImgs/Round Plate/12_-4ComRoundPlateFood.webp';
import rp12 from './../Assets/productImgs/Round Plate/12_RoundPlate.webp';
// import rpf12 from './../Assets/productImgs/Round Plate/12_RoundPlateFood.webp';
import op from './../Assets/productImgs/Round Plate/OvalPlate.webp';
// import opf from './../Assets/productImgs/Round Plate/OvalPlatefood.webp';
import wc140 from './../Assets/productImgs/Wooden Cutlery/140MmWoodenCutlery.webp';
// import wcf140 from './../Assets/productImgs/Wooden Cutlery/140MmWoodenCutleryFood.webp';
import wc160 from './../Assets/productImgs/Wooden Cutlery/160MmWoodenCutlery.webp';
// import wcf160 from './../Assets/productImgs/Wooden Cutlery/160MmWoodenCutleryFood.webp';
import ws from './../Assets/productImgs/Wooden Cutlery/WoodenStirrer.webp';
import crpp3 from './../Assets/productImgs/Palm Leaf/3CompartmentRoundPalmPlate.webp';
import spp4 from './../Assets/productImgs/Palm Leaf/Palm4_SquarePlate.webp';
import spp7 from './../Assets/productImgs/Palm Leaf/Palm7_SquarePlate.webp';
import spp8 from './../Assets/productImgs/Palm Leaf/Palm8_SquarePlate.webp';
import spp10 from './../Assets/productImgs/Palm Leaf/Palm10_SquarePlate.webp';
import prb from './../Assets/productImgs/Palm Leaf/PalmRoundBowl.webp';
import rp from './../Assets/productImgs/Palm Leaf/RectangalePlate.webp';
import rsbp from './../Assets/productImgs/Palm Leaf/RoundSoupBowlPalm.webp';
 
export const details = [
    {
        photo: eco,
        category: '',
        title: 'Eco Friendly Products',
        subtitle: 'Eco friendly products like Clamshell Container, Bowls, Plates....',
        more: "we strongly believe that every action can have a positive reaction and that every change that we imbibe, however miniscule will generate its own positive impact in the future. This is why we have devised our new age biodegradable and compostable line of tableware is envisioned to create a shroud of much needed green today, for the future to reap tomorrow. Our compostable and biodegradable line of tableware is made of 100% natural eco friendly pulp. Eco friendly is the chosen ingredient in our success story of the future because it is a non-food renewable resource which yields a new harvest year after year for generations to come. After the eco friendly stalk has been processed for its. primary ingredient; Eco friendly, there is a plant fibre that stays behind as a remnant.",
        subcat: [
        {
            head: "Clamshell Container",
            tag1:t1,
            tag2:t2,
            tag3:t3,
            tag4:t4,
            tag5:t5,
            products:[
                {
                    prpic: cb6,
                    pr: "6 x 6 inch Hamburger Box",
                    dim: "Dimensions (in inch) :  6 x 6",
                    num1: "Number Of Pieces Per Pack: 50 pcs",
                    num2: "Number of Pieces Per Case: 500 pcs",
                },
                {
                    prpic: cb600,
                    pr: "600 ml Clamshell Container",
                    dim: "Dimensions (in inch) :  7 x 5",
                    num1: "Number Of Pieces Per Pack: 50 pcs",
                    num2: "Number of Pieces Per Case: 500 pcs",
                },
                {
                    prpic: sbbc,
                    pr: "Small bio Box 'Fish & Chips'",
                    dim: "Dimensions (in mm) :  7 inch x 5 inch",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 280",
                },
                {
                    prpic: "",
                    pr: "Medium bio Box 'Fish & Chips'",
                    dim: "Dimensions (in mm) :  9 inch x 6 inch",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 280",
                },
                {
                    prpic: "",
                    pr: "9 x 6 inch Clamshell Box",
                    dim: "Dimensions (in inch) :  9 x 6",
                    num1: "Number Of Pieces Per Pack: 50 pcs",
                    num2: "Number Of Pieces Per Case: 500 pcs",
                },
                {
                    prpic: "",
                    pr: "9 x 6 inch 2 Compartment Clamshell Box",
                    dim: "Dimensions (in inch) :  9 x 6",
                    num1: "Number Of Pieces Per Pack: 50 pcs",
                    num2: "Number Of Pieces Per Case: 500 pcs",
                },
                {
                    prpic: "",
                    pr: "8 x 8 inch 1 compartment clamshell Box",
                    dim: "Dimensions (in inch) :  8 x 8",
                    num1: "Number Of Pieces Per Pack: 100 pcs",
                    num2: "Number Of Pieces Per Case: 200 pcs",
                },
                {
                    prpic: "",
                    pr: "9 x 9 inch 1 Compartment Clamshell Box",
                    dim: "Dimensions (in inch) :  9 x 9",
                    num1: "Number Of Pieces Per Pack: 100 pcs",
                    num2: "Number Of Pieces Per Case: 200 pcs",
                },
                {
                    prpic: "",
                    pr: "9 x 9 inch 3 Compartment Clamshell Box",
                    dim: "Dimensions (in inch) :  9 x 9",
                    num1: "Number Of Pieces Per Pack: 100 pcs",
                    num2: "Number Of Pieces Per Case: 200 pcs",
                },
                {
                    prpic: cc340,
                    pr: "340ml (12oz) Container(Bagasse Lid Available)",
                    dim: "Dimensions (in mm) :  130 x 62",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 1000",
                },
                {
                    prpic: cc500,
                    pr: "500ml (16oz) Container(Bagasse Lid Available)",
                    dim: "Dimensions (in mm) :  130 x 80",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 100",
                },
                {
                    prpic: rc500,
                    pr: "500ml Rectangular Container",
                    dim: "Dimensions (in mm) :  174 x 119 x 42",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 1600",
                },
            ]
        },
        {
            head: "Bowls",
            tag1:t1,
            tag2:t2,
            tag3:t3,
            tag4:t4,
            tag5:t5,
            products:[
                {
                    prpic: cup50,
                    pr: "50 ml cup (lid available)",
                    dim: "Dimensions (in mm) :  62 x 15",
                    num1: "Number Of Pieces Per Pack: 100",
                    num2: "Number Of Pieces Per Case: 10000",
                },
                {
                    prpic: cup100,
                    pr: "100 ml cup",
                    dim: "Dimensions (in mm) :  85 x 40",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Case: 3600",
                },
                {
                    prpic: cup180,
                    pr: "BOWLS (180 ML)",
                    dim: "Dimensions (in mm) :  100 x 45",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 1500",
                },
                {
                    prpic: cup240,
                    pr: "BOWLS (240 ML)",
                    dim: "Dimensions (in mm) :  113 x 43",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 1500",
                },
                {
                    prpic: sbc,
                    pr: "SQUARE BOWL",
                    dim: "Dimensions (in mm) :  105 x 105",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 2250",
                },
                {
                    prpic: oz12bowl,
                    pr: "12oz Bowl (Large Bowl)",
                    dim: "Dimensions (in mm) :  155 x 45",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Case: 1000",
                },
            ]
        },
        {
            head: "Plates",
            tag1:t1,
            tag2:t2,
            tag3:t3,
            tag4:t4,
            tag5:t5,
            products: [
                {
                    prpic: rp6,
                    pr: `6" ROUND PLATE`,
                    dim: "Dimensions (in mm) :  155 x 16",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Case: 2200",
                },
                {
                    prpic: rp7,
                    pr: `7” ROUND PLATE`,
                    dim: "Dimensions (in mm) :  180 x 18",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Case: 2200",
                },
                {
                    prpic: rp9,
                    pr: `9" ROUND PLATE`,
                    dim: "Dimensions (in mm) :  230 x 20",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Case: 1000",
                },
                {
                    prpic: rp10,
                    pr: `10" ROUND PLATE`,
                    dim: "Dimensions (in mm) :  260 x 20",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Case: 1000",
                },
                {
                    prpic: rp12,
                    pr: `12" ROUND PLATE`,
                    dim: "Dimensions (in mm) :  285 x 30",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Case: 900",
                },
                {
                    prpic: crp9,
                    pr: `9"-3 COMPARTMENT PLATE`,
                    dim: "Dimensions (in mm) :  230 x 24",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Case: 900",
                },
                {
                    prpic: crp10,
                    pr: `10"-3 COMPARTMENT PLATE`,
                    dim: "Dimensions (in mm) :  260 x 23",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Case: 800",
                },
                {
                    prpic: crp11,
                    pr: `11"-4 COMPARTMENT PLATE`,
                    dim: "Dimensions (in mm) :  275 x 27",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Case: 500",
                },
                {
                    prpic: crp12,
                    pr: `12"-4 COMPARTMENT PLATE`,
                    dim: "Dimensions (in mm) :  290 x 28",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Case: 900",
                },
                {
                    prpic: op,
                    pr: `Oval PLATTER`,
                    dim: "Dimensions (in mm) :  315 x 255 x 22",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Carton: 900",
                },
                {
                    prpic: crp7,
                    pr: `7 COMPARTMENT ROUND PLATE`,
                    dim: "Dimensions (in mm) :  280 x 32",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Case: 200",
                },
                {
                    prpic: crp9,
                    pr: `9 COMPARTMENT ROUND PLATE`,
                    dim: "Dimensions (in mm) :  315 x 32",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Case: 200",
                },
            ]
        },
        {
            head: "Trays",
            tag1:t1,
            tag2:t2,
            tag3:t3,
            tag4:t4,
            tag5:t5,
            products:[
                {
                    prpic: st1,
                    pr: "1D MULTIPURPOSE SQUARE TRAY",
                    dim: "Dimensions (in mm) :  5.5 x 20",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 2250",
                },
                {
                    prpic: rt2,
                    pr: "2D MULTIPURPOSE RECTANGULAR TRAY",
                    dim: "Dimensions (in mm) :  215 x 142 x 20",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 1500",
                },
                {
                    prpic: mt5,
                    pr: "5 CP MEAL TRAY(Lid Available)",
                    dim: "Dimensions (in mm) :  280 x 270",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Carton: 400",
                },
                {
                    prpic: smt5,
                    pr: "5 CP SCHOOL MEAL TRAY",
                    dim: "Dimensions (in mm) :  270 x 215",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "Number Of Pieces Per Carton: 700",
                },
                {
                    prpic: cmt6,
                    pr: "6 COMPARTMENT MEAL TRAY",
                    dim: "Dimensions (in mm) :  280 x 220",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 300",
                },
            ]
        },
        {
            head:"Wooden Cutlery",
            tag1:t1,
            tag2:t2,
            tag3:"",
            tag4:"",
            tag5:"",
            products:[
                {
                    prpic: wc140,
                    pr: "WOODEN FORK/SPOON/KNIFE",
                    dim: "Dimensions : 140 mm Long",
                    num1: "Number Of Pieces Per Pack: 100",
                    num2: "Number Of Pieces Per Carton: 10,000",
                },
                {
                    prpic: wc160,
                    pr: "WOODEN FORK/SPOON/KNIFE",
                    dim: "Dimensions : 160 mm Long",
                    num1: "Number Of Pieces Per Pack: 100",
                    num2: "Number Of Pieces Per Carton: 10,000",
                },
                {
                    prpic: ws,
                    pr: "WOODEN STIRRER",
                    dim: "Dimensions : 140 mm Long",
                    num1: "Number Of Pieces Per Pack: 100",
                    num2: "Number Of Pieces Per Carton: 20,000",
                },
            ]
        },
        {
            head:"Paper Straw",
            tag1:t1,
            tag2:t2,
            tag3:"",
            tag4:"",
            tag5:"",
            products:[
                {
                    prpic: spc6,
                    pr: "6MM PAPER STRAWS",
                    dim: "Dimensions (in mm) :  6 x 200 (White/Mixed Stripes)",
                    num1: "Number Of Pieces Per Pack: 100",
                    num2: "Number Of Pieces Per Carton: 5000",
                },
                {
                    prpic: psc8,
                    pr: "8MM PAPER STRAWS",
                    dim: "Dimensions (in mm) :  8 x 200 (White/Mixed Stripes)",
                    num1: "Number Of Pieces Per Pack: 100",
                    num2: "Number Of Pieces Per Carton: 5000",
                },
                {
                    prpic: psc10,
                    pr: "10MM PAPER STRAWS",
                    dim: "Dimensions (in mm) :  10 x 200 (White/Mixed Stripes)",
                    num1: "Number Of Pieces Per Pack: 100",
                    num2: "Number Of Pieces Per Carton: 5000",
                },
                {
                    prpic: iwps,
                    pr: "Individual Wrap straws",
                    dim: "Dimensions (in mm) :  6/8 x 200 (White/Mixed Stripes)",
                    num1: "Number Of Pieces Per Pack: 50",
                    num2: "Number Of Pieces Per Carton: 5000",
                },
            ]
        },
        {
            head:"Palm Leaf Products",
            tag1:t1,
            tag2:t2,
            tag3:t3,
            tag4:t4,
            tag5:t5,
            products:[
                {
                    prpic: sbc,
                    pr: "Square Bowl",
                    dim: "Dimensions (in mm) :  127 x 76.2",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: sbc,
                    pr: "Square Bowl",
                    dim: "Dimensions (in mm) :  241 x 38",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: op,
                    pr: "Oval Plate",
                    dim: "Dimensions (in mm) :  254 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: rp10,
                    pr: `10” Round Plate`,
                    dim: "Dimensions (in mm) :  254 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: rp6,
                    pr: `6” Round Plate`,
                    dim: "Dimensions (in mm) :  152.4 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: rp9,
                    pr: `9” Round Plate`,
                    dim: "Dimensions (in mm) :  228.6 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: rsbp,
                    pr: `Round Soup Bowl`,
                    dim: "Dimensions (in mm) :  241 x 38",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: spp10,
                    pr: `10” Square Plate`,
                    dim: "Dimensions (in mm) :  254 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: spp8,
                    pr: `8” Square Plate`,
                    dim: "Dimensions (in mm) :  203.2 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: spp7,
                    pr: `7” Square Plate`,
                    dim: "Dimensions (in mm) :  177.8 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: spp4,
                    pr: `4” Square Plate`,
                    dim: "Dimensions (in mm) :  101.6 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: crpp3,
                    pr: `Round 3 Compartment Plate`,
                    dim: "Dimensions (in mm) :  304.8 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: prb,
                    pr: `Round Bowl`,
                    dim: "Dimensions (in mm) :  152.4 x 101.6",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
                {
                    prpic: rp,
                    pr: `Rectangle Plate`,
                    dim: "Dimensions (in mm) :  228.6 x 152.4 x 25.4",
                    num1: "Number Of Pieces Per Pack: 25",
                    num2: "",
                },
            ]
        },
        ],
        button: 'click me',
    },
];