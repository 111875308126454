const FooterCompanyDetails = () => {
  return (
    <div className="flex flex-col items-start justify-center w-4/5 md:w-1/5">
      <a href="https://goo.gl/maps/podBa2pVkvCztqqZ8" className="flex flex-row items-start justify-center my-3">
        <span className="mr-4">
          <i className="text-2xl text-yellowColor fa-solid fa-map-location"></i>
        </span>
        <p className="duration-200 text-white/80 hover:text-white">B/28/FF-Sardar Patel Mall,B/S Diamond Mill,Nicol Gam Road,Ahmedabad:382350</p>
      </a>
      <a href="tel:6353363645" className="flex flex-row items-start justify-center my-3">
        <span className="mr-4">
          <i className="text-2xl text-yellowColor fa-solid fa-phone"></i>
        </span>
        <p className="duration-200 text-white/80 hover:text-white">{`63533 63645`}</p>
      </a>
      <a href="tel:9726695366" className="flex flex-row items-start justify-center my-3">
        <span className="mr-4">
          <i className="text-2xl text-yellowColor fa-solid fa-phone"></i>
        </span>
        <p className="duration-200 text-white/80 hover:text-white">9726695366</p>
      </a>
      <a href="mailto:ganeshinternationaltrade@gmail.com " className="flex flex-row lg:text-xl text-lg items-start justify-center my-3">
        <span className="mr-4">
          <i className="text-2xl text-yellowColor fa-regular fa-envelope-open"></i>
        </span>
        <p className="duration-200 text-white/80 hover:text-white">ganeshinternationaltrade@gmail.com </p>
      </a>
    </div>
  );
};

export default FooterCompanyDetails;
