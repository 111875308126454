import React from "react";
// import BrochureSection from "../SubscribeSection/BrochureSection";
import FooterCompany from "./Components/FooterCompany";
import FooterCompanyDetails from "./Components/FooterCompanyDetails";
import FooterLinks from "./Components/FooterLinks";

const Footer = () => {
    return (
        <>
            {/* <BrochureSection/> */}
            <div className="bg-gray-900">
                <div className="container mx-auto overflow-hidden">
                    <div className="flex flex-col overflow-hidden items-center py-20 text-white  md:flex-row justify-evenly text-whitemy-2">
                        <FooterCompanyDetails />
                        <FooterCompany />
                        <FooterLinks /> 
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
