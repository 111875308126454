import React from "react";
import Popups from "reactjs-popup";
import { toast } from 'react-hot-toast';
import client from "../../../client/airtable";
import { useForm, Controller } from 'react-hook-form';

const contentStyle = {
    maxWidth: "500px",
    width: "90%",
};


const CustomModal = (props) => {

    const {
            handleSubmit,
            control,
            formState: { errors },
            reset,
        } = useForm();



    const onSubmit = async (data) => {
        const toaster = toast.loading('Sending your message...');

        try {
            const LEARNN_FORM_BASE_ID = "appdbWcasV2oCfpxm";
            const TABLE_NAME = "Projects";
            const response = await client.post(`${LEARNN_FORM_BASE_ID}/${TABLE_NAME}`,

                // data,
                {
                    records: [
                        {
                            fields: {
                            Name: data.name,
                            Email: data.email,
                            Message: data.message,
                            phoneNumber: data.phoneNumber,
                            product: props.title,
                            },
                        },
                    ],
                }
            );
            // console.log(response);


            console.log('Record created:', response);
            reset();
            toast.success('Message sent successfully');
            // Reset form fields after successful submission

        } catch (error) {
            console.error('Error creating record:', error);
            toast.error('Error sending your message');
        }
        toast.dismiss(toaster);
    };


    return (

    <Popups
        trigger={<button className="button text-base"> Inquiry Now </button>}
        modal
        contentStyle={contentStyle}
    >

        {close => (
            <div className="fixed inset-0 z-[1000] place-items-center overflow-auto bg-white bg-opacity-10 backdrop-blur-sm">
                {/* <a className=" close cursor-pointer text-black p-1 text-4xl" onClick={close} href="">
        //   &times;
        // </a> */}

                <div className="actions">
                    <Popups
                        // trigger={<button className="button"> Menu Demo </button>}
                        position="top center"
                        closeOnDocumentClick
                        contentStyle={{ padding: "0px", border: "none" }}
                    >
                    </Popups>

                </div>
                {/* </div> */}
                <div>
                    <div className='lg:w-2/5  mx-auto pt-16 pb-10'>
                            <span className="cursor-pointer absolute top-auto left-auto px-3 py-1 bg-gray-900"
                                onClick={() => {
                                    close();
                                }}>
                                    <i className="fa-solid text-yellowColor text-2xl fa-xmark"></i>
                                </span>
                            <form
                                className='flex bg-[#FFE300] rounded-lg flex-col justify-center items-center p-6 pt-10'
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <h2 className='pb-10 text-2xl'>Contact Form</h2>
                                <div className='flex flex-col space-y-2 lg:w-5/6 w-2/3'>
                                    <Controller
                                        name='name'
                                        control={control}
                                        defaultValue=''
                                        rules={{ required: true, pattern: /^[A-Za-z\s]+$/ }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                className={`bg-slate-200 p-1 ${errors.name ? 'border-red-500' : ''}`}
                                                type='text'
                                                placeholder='Name'
                                            />
                                        )}
                                    />
                                    {errors.name && (
                                        <p className='text-red-500'>Name can only contain letters and spaces</p>
                                    )}

                                    <Controller
                                        name='email'
                                        control={control}
                                        defaultValue=''
                                        rules={{
                                            required: true,
                                            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                            validate: (value) => value.includes('gmail.com'),
                                        }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                className={`bg-slate-200 p-1 ${errors.email ? 'border-red-500' : ''}`}
                                                type='email'
                                                placeholder='Email: xyz@gmail.com'
                                            />
                                        )}
                                    />
                                    {errors.email && <p className='text-red-500'>Invalid email format or not a Gmail address</p>}

                                    <Controller
                                        name='phoneNumber'
                                        control={control}
                                        defaultValue=''
                                        rules={{ required: true, pattern: /^[0-9]{10}$/ }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                className={`bg-slate-200 p-1 ${errors.phoneNumber ? 'border-red-500' : ''}`}
                                                type='tel'
                                                placeholder='Phone Number: 1234567890'
                                            />
                                        )}
                                    />
                                    {errors.phoneNumber && (
                                        <p className='text-red-500'>10 Number Phone Number is necessary</p>
                                    )}

                                    <Controller
                                        name='message'
                                        control={control}
                                        defaultValue=''
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                className={`bg-slate-200 p-1 lg:pb-10 ${errors.message ? 'border-red-500' : ''
                                                    }`}
                                                placeholder='Message: eg: get a quote for a product'
                                            />
                                        )}
                                    />
                                    {errors.message && <p className='text-red-500'>Message is required</p>}

                                    <div className='p-2'></div>
                                    <button
                                        className='border-2 text-white border-[#FFE300] min-w-full mx-auto p-1 bg-black'
                                        type='submit'
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>

                    </div>
                </div>
            </div>
        )
        }
    </Popups>
);

};

export default (CustomModal);


