import {details} from './../../../Constant/constant';
import Cont from './ContactForm';
import { Link } from 'react-router-dom';
import SectionHeading from '../../../Components/SectionHeading';

const ImagesGalleryData = () => {
  return (
    <>
      {/* #1 */}

      <section className="text-gray-600 body-font">
        <div className="container px-5 mx-auto">
          <SectionHeading heading="Our Products" />
          <div className="flex flex-wrap">
              {details.map((data,index)=>(
                <div key={index} className="border-2 border-gray-200 border-opacity-60 rounded-lg w-72 m-auto md:flex md:w-2/3">
                  <img className="md:w-1/2" src={data.photo} loading="lazy" alt="blog" key={index}/>
                  <div className="p-6 md:w-1/2">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">{data.category}</h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{data.title}</h1>
                    <p className="leading-relaxed mb-3">{data.subtitle}</p>
                    <div className="flex items-center flex-row gap-2">
                      <Link to={`/products/${data.title}`} className='p-[6px] bg-[#FFE300] rounded-md text-black text-base button'>More</Link>
                      <button className="p-1 bg-[#FFE300] rounded-md text-black">
                        <Cont photo={data.photo} title={data.title} more={data.more}/>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
        </div>
      </section>
    </>
  );
};

export default ImagesGalleryData;