import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import IntroSection from "./Sections/SliderSection/IntroSection";
import { AnimatePresence } from "framer-motion";
import Navbar from "./Components/Navigation/Navbar";
import LoginContext from "./Context/LoginContext";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ContactForm from "./Components/FormComponent/ContForm";
import Description from "./Sections/ImageGallery/Components/Description";


function App() {
    const location = useLocation();

    // states of input forms
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [phNumber, setPhNumber] = useState("");
    const [emailId, setEmailId] = useState("");
    const [message, setMessage] = useState("");
    const [showPopupBox, setShowPopupBox] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        setTimeout(() => {
            if (location.pathname !== "/contact") {
                setShowPopupBox(true);
            }
        }, 15000);
    }, [location.pathname]);
    return (
        <>
            <div className="AppSeo">
                <Helmet>
                    <meta charset="UTF-8"></meta>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                    <meta name="description" content="We are a dedicated provider of eco-conscious disposable items. 
                    We provide globally import and export service to our Valuable Clients."></meta>
                    
                    <meta property="og:title" content="Ganesh International Trade"/>
                    <meta property="og:description" content="We are a dedicated provider of eco-conscious disposable items."/>
                    {/* <meta property="og:image" content={favi} /> */}
                    <meta name="keywords" content="import export eco-friendly eco-friendly disposable products, from clamshell containers, bowls, plates, and trays to wooden cutlery, paper straws, and palm leaf items."></meta> 
                    <meta name="keywords" content="We have a well-established global clientele. The company understands the importance of Quality and Timely Delivery of merchandise in this competitive and challenging International Business scenario."></meta> 
                    <meta name="keywords" content=" Weaving Worldwides, Thread by Thread."></meta>
                    <meta name="keywords" content="We provide reliable and fast delivery import and export"></meta>
                    <meta name="language" content="English" />
                    <meta name="author" content="Akash" />
                </Helmet>
            </div>



            <LoginContext.Provider value={{ showPopupBox, setShowPopupBox, email, setEmail, fullName, setFullName, phNumber, setPhNumber, emailId, setEmailId, message, setMessage, userName, setUserName, password, setPassword }}>
                <Navbar /> 
                {/* <GetInTouchPopup /> */}
                <AnimatePresence exitBeforeEnter={true}>
                    <Routes key={location.key} location={location}>
                        <Route path="/" exact element={<IntroSection />} />
                        {/* <Route path="/admin" exact element={<Admin />} /> */}
                        {/* <Route path="/contact" exact element={<ContactSection />} /> */}
                        <Route path="/contact" exact element={<ContactForm />} />
                        <Route path="/products/:title" exact element={<Description />} />
                    </Routes>
                {/* <Popup />     */}
                </AnimatePresence>
            </LoginContext.Provider>
            {/* <Slideshow/> */}
        </>
    );
}

export default App;
