import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { details } from './../../../Constant/constant';
import Cont from './ContactForm';
import Footer from '../../FooterSection/Footer';

function Description() {
  const { title } = useParams();
  useEffect(()=>{
    window.scrollTo(0,0);
  },[title]);
  return (
    <>
      {details.filter((data) => data.title === title).map(data =>
        <>
          <h1 className="pt-5 text-4xl leading-[3.2rem] md:leading-none text-center">{data.title}</h1>
          <div className="block w-20 h-1 m-auto rounded md:my-5 bg-yellowColor "></div>
          <div className='md:flex md:p-8'>
            <img src={data.photo} className="w-full my-8 md:w-96 lg:w-1/2 xl:w-[500px]" alt='product'/>
            <div className='m-4 md:w-1/2 md:pl-10 xl:w-auto'>
              <h1 className='text-3xl  my-10'>About {data.title}</h1>
              <p>{data.more}</p>
            </div>
          </div>
          {data.subcat.map((sub, index) => (
            <>
              <h1 className='text-3xl text-center my-10' key={index}>{sub.head}</h1>
              <div className='flex flex-wrap align-middle justify-center'>
                <img src={sub.tag1} className="p-1 w-[20%] lg:w-52 lg:p-3" alt="tag"/>
                <img src={sub.tag2} className="p-1 w-[20%] lg:w-52 lg:p-3" alt="tag"/>
                {sub.tag3&&<img src={sub.tag3} className="p-1 w-[20%] lg:w-52 lg:p-3" alt="tag"/>}
                {sub.tag4&&<img src={sub.tag4} className="p-1 w-[20%] lg:w-52 lg:p-3" alt="tag"/>}
                {sub.tag5&&<img src={sub.tag5} className="p-1 w-[20%] lg:w-52 lg:p-3" alt="tag"/>}
              </div>
              <div className='flex flex-wrap align-middle justify-center'>
                {sub.products.map((d, index) => (
                  <>
                    <div className="border-2 border-gray-200 border-opacity-60 rounded-lg w-64 m-5" key={index}>
                      <div>
                        {d.prpic&&<div><img src={d.prpic} alt="sample" /></div>}
                        <div className='p-6'> 
                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{d.pr}</h1>
                        <p className="leading-relaxed mb-3">{d.dim}</p>
                        <p className="leading-relaxed mb-3">{d.num1}</p>
                        <p className="leading-relaxed mb-3">{d.num2}</p>
                        <div className="flex items-center flex-row gap-2">
                          <button className="p-1 bg-[#FFE300] rounded-md text-black">
                            <Cont photo={data.photo} title={d.pr} more={data.more} />
                          </button>
                        </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ))}
        </>
      )}
      <Footer />
    </>
  )
}

export default Description;