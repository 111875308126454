import React, { useEffect } from "react";

const GoogleTranslate = () => {
    useEffect(() => {
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                { pageLanguage: "en" },
                "google_translate_element"
            );
        };

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        script.async = true;

        document.body.appendChild(script);

        window.googleTranslateElementInit = googleTranslateElementInit;

        return () => {
            // Clean up the script when the component unmounts
            document.body.removeChild(script);
            delete window.googleTranslateElementInit;
        };
    }, []);

    return (
        <div id="google_translate_element" style={{ backgroundColor: 'transparent' }}>
            <style>
                {`
                    /* Google translator button styles */
                    #google_translate_element {
                        display: flex;
                        margin-left: 10px; /* Adjust as needed */
                    }
                    .goog-te-gadget {
                        // display: flex;
                    }
                    .goog-te-combo {
                        color: black; /* Specify your text color here */
                        font-size: 18px !important;
                    }
                    .VIpgJd-ZVi9od-l4eHX-hSRGPd {
                        display: none;
                    }
                    .skiptranslate {
                        color: transparent;
                    }
                `}
            </style>
        </div>
    );
};

export default GoogleTranslate;
