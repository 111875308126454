import React, { useEffect } from "react";
import AboutCompany from "../AboutCompany/AboutCompany";
import Footer from "../FooterSection/Footer";
// import SubscribeSection from "../SubscribeSection/SubscribeSection";
import WhatWeDoing from "../WhatWeDoingSection/WhatWeDoing";
import MultipleBlocks from "./Components/MultipleBlocks";
import SliderIntro from "./Components/SliderIntro";
import Certificate from "../Certificates/Certificate.jsx";
import ImagesGalleryData from "../ImageGallery/Components/ImagesGalleryData";

import certi1 from "../../Assets/logo6.jpg";
import certi2 from "../../Assets/logo3.jpg";
import certi3 from "../../Assets/logo5.png";
import certi4 from "../../Assets/msme.png";
import certi5 from "../../Assets/dgft.jpg";
import certi6 from "../../Assets/logo7.jpg";

const IntroSection = () => {
  useEffect(() => {
    document.title = "Ganesh International Trade";
    window.scroll(0, 0);
  }, []);
  
  const certificates = [
    {
      id: 1,    
      image: certi1,
    },
    {
      id: 2,
      image: certi2,
    },
    { 
      id: 3,
      image: certi3,
    },
    { 
      id: 4,
      image: certi4,
    },
    { 
      id: 5,
      image: certi5,
    },
    {
      id: 6,
      image: certi6,
    },
    
  ];

  return (
    
    <>
      <SliderIntro />
      <MultipleBlocks />
      <WhatWeDoing /> 
      <ImagesGalleryData />
      <AboutCompany />
      <Certificate certificates={certificates} />
      {/* <IntroCeo /> */}
      {/* <SubscribeSection /> */}
      <Footer />
    </>
  );
};

export default IntroSection;
