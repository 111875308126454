import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";


// import slide2 from "../../../Assets/truck2.jpeg";

// import slide5 from "../../../Assets/bg-1.jpg";
// import slide6 from "../../../Assets/truck4.jpeg";
// import slide7 from "../../../Assets/bg-3.jpg";

import slide1 from "../../../Assets/truck2.jpeg";
import slide2 from "../../../Assets/bg-1.jpg";
import slide3 from "../../../Assets/s1_welcome2.jpeg";
import slide4 from "../../../Assets/s3_quality.jpeg";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const SliderIntro = () => {
    const content = [
        {
            title: "Ganesh International Trade",
            description: "Welcome to world of import export",
            image: slide1
        },
        // { title: "SHREEBAI INDUSTRY ", description: "Combine Technology for Better Quality", image: image2 },
        {
            title: "Ganesh International Trade",
            description: "We provide reliable and fast delivery",
            image: slide2
        },
        {
            title: "Ganesh International Trade",
            description: "Welcome to Ganesh International Trade",
            image: slide3
        },
        {
            title: "Ganesh Cargo Shipment",
            description: "We provide premium quality products",
            image: slide4
        },
    ];

    const animations = {
        heading: {
            initial: { opacity: 0, top: -100 },
            exit: { opacity: 0, top: -100, transition: { duration: 0.5 } },
            animate: { opacity: 1, top: 0, transition: { duration: 0.7 } },
        },
        overview: {
            initial: { opacity: 0, top: -50 },
            exit: { opacity: 0, top: -50, transition: { duration: 0.3 } },
            animate: { opacity: 1, top: 0, transition: { duration: 0.3, delay: 0.4 } },
        },
        btn: {
            initial: { opacity: 0, top: -30 },
            exit: { opacity: 0, top: -30, transition: { duration: 0.3 } },
            animate: { opacity: 1, top: 0, transition: { duration: 0.3, delay: 0.6 } },
        },
    };
    return (
        <>
            {/* <AnimatePresence exitBeforeEnter={true}> */}
            <AutoplaySlider
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={3000}
                bullets={false}
                className={"h-[35rem] md:h-[70vh]"}
                infinite={true}
            >
                {content.map((item, index) => (
                    // <div key={index} className="flex items-center justify-center bg-center bg-cover" style={{ backgroundImage: `url('${item.image}')` }}>
                    <div key={index} className="flex flex-col items-center justify-center"
                        style={{
                            // background: `linear-gradient(rgb(0 0 0 / 80%), rgb(0 0 0 / 75%)), url(${item.image})`,
                            backgroundImage: `linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 45%)), url(${item.image})`,
                            backgroundRepeat: 'no-repeat', // Prevent repeating in the x-axis
                            backgroundSize: 'cover', // Adjust the size as needed
                            backgroundPosition: 'center center', // Adjust the position as needed
                            // Add more CSS properties as needed
                        }}
                    >
                        <div className="flex flex-col items-center justify-center text-center center">
                            <motion.h1 whileInView="animate" viewport={{ once: true }} variants={animations.heading} initial="initial" animate="animate" exit="exit" className="relative text-5xl font-medium md:text-7xl text-gray-50">
                                {item.title}
                            </motion.h1>
                            <motion.p whileInView="animate" viewport={{ once: true }} variants={animations.overview} initial="initial" animate="animate" exit="exit" className="relative w-4/6 mt-5 text-2xl font-normal text-yellow-400 md:w-auto">
                                {item.description}
                            </motion.p>
                            <motion.div whileInView="animate" viewport={{ once: true }} variants={animations.btn} initial="initial" animate="animate" exit="exit" className="relative my-8">
                                <Link to="/contact" className="w-32 p-2 my-10 text-center text-white duration-200 border-2 md:p-3 hover:text-gray-800 hover:bg-yellowColor border-yellowColor">
                                    Contact Us
                                </Link>
                            </motion.div>
                        </div>
                    </div>
                ))}
            </AutoplaySlider>
            {/* </AnimatePresence> */}
        </>
    );
};

export default SliderIntro;
