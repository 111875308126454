// import React, { useState } from 'react';
// import axios from 'axios';
// import client from '../../client/airtable';
// import { toast } from 'react-hot-toast';
// import GoogleMap from '../GoogleMap';

// const AirtableForm = () => {
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [phoneNumber, setPhoneNumber] = useState('');
//     const [message, setMessage] = useState('');

//     // console.log(name, email, phoneNumber, message);
//     const handleSubmit = async (e) => {
//         const toaster = toast.loading('Sending your message...');
//         e.preventDefault();

//         // const data = {
//         //     records: [
//         //         {
//         //             fields: {
//         //                 Name: name,
//         //                 Email: email,
//         //                 Message: message,
//         //                 PhoneNumber: phoneNumber,
//         //             },
//         //         },
//         //     ],
//         // };
//         // console.log(data);

//         try {
//             const LEARNN_FORM_BASE_ID = process.env.REACT_APP_LEARNN_FORM_BASE_ID;
//             const TABLE_NAME = process.env.REACT_APP_TABLE_NAME;
//             const response = await client.post(`${LEARNN_FORM_BASE_ID}/${TABLE_NAME}`,

//                 // data,
//                 {
//                     records: [
//                         {
//                             fields: {
//                                 Name: name,
//                                 Email: email,
//                                 Message: message,
//                                 phoneNumber: phoneNumber,
//                                 // typecast: true
//                             },
//                         },
//                     ],
//                     // {
//                     //         headers: {
//                     //             Authorization: `Bearer patTUPRwVG2wCPhIh.d1d9a2112b06f3a3b7327d53035c037e2419389643085154ff7423f68cbb62fa`,
//                     //             "content-type": "application/json",
//                     //         },
//                     //     // body: data,
//                     // }
//                 }
//             );
//             // console.log(response);


//             console.log('Record created:', response);

//             // Reset form fields after successful submission
//             setName('');
//             setEmail('');
//             setPhoneNumber('');
//             setMessage('');
//         } catch (error) {
//             console.error('Error creating record:', error);
//             toast.error('Error sending your message');
//         }
//         toast.dismiss(toaster);
//         toast.success('Message sent successfully');
//     };

//     return (
//         <>
//             <div className='lg:w-2/5  mx-auto pt-16 pb-10'>
//                 <form className='flex bg-[#FFE300] rounded-lg flex-col justify-center items-center p-6 pt-10' onSubmit={handleSubmit}>
//                     <h2 className='pb-10 text-2xl'>Contact Form</h2>
//                     <div className='flex flex-col space-y-2 lg:w-5/6 w-2/3'>
//                         <input
//                             className='bg-slate-200 p-1'
//                             pattern="[A-Za-z\s]+"
//                             title="Name can only contain letters and spaces"
//                             type="text"
//                             placeholder="Name"
//                             value={name}
//                             onChange={(e) => setName(e.target.value)}
//                             required
//                         />

//                         <input
//                             className='bg-slate-200 p-1'
//                             type="email"
//                             placeholder="Email: xyz@gmail.com"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             required
//                         />
//                         <input
//                             className='bg-slate-200 p-1'
//                             type="tel"
//                             placeholder="Phone Number: 1234567890"
//                             title="10 Number Phone Number is necessary"
//                             value={phoneNumber}
//                             onChange={(e) => setPhoneNumber(e.target.value)}
//                             required
//                         pattern="[0-9]{10}"
//                         />
//                         <textarea
//                             className='bg-slate-200 p-1 lg:pb-10'
//                             placeholder="Message: eg: get a quote for a product"
//                             value={message}
//                             onChange={(e) => setMessage(e.target.value)}
//                             required
//                         />
//                         <div className='p-2'></div>
//                         <button className='border-2 text-white border-[#FFE300] min-w-full mx-auto p-1 bg-black' type="submit">Submit</button>
//                     </div>
//                 </form>


//             </div>
//             <GoogleMap />
//         </>
//     );
// };

// export default AirtableForm;

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import client from '../../client/airtable';
import { toast } from 'react-hot-toast';
import GoogleMap from '../GoogleMap';
import Footer from '../../Sections/FooterSection/Footer';

const AirtableForm = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        const toaster = toast.loading('Sending your message...');

        try {
            const LEARNN_FORM_BASE_ID = process.env.REACT_APP_LEARNN_FORM_BASE_ID;
            const TABLE_NAME = process.env.REACT_APP_TABLE_NAME;
            const response = await client.post(`${LEARNN_FORM_BASE_ID}/${TABLE_NAME}`, {
                records: [
                    {
                        fields: {
                            Name: data.name,
                            Email: data.email,
                            Message: data.message,
                            phoneNumber: data.phoneNumber,
                        },
                    },
                ],
            });

            console.log('Record created:', response);

            // Reset the form fields after successful submission
            reset();
            toast.success('Message sent successfully');
        } catch (error) {
            console.error('Error creating record:', error);
            toast.error('Error sending your message');
        }

        toast.dismiss(toaster);
    };

    return (
        <>
            <div className='lg:w-2/5 mx-auto pt-16 pb-10'>
                <form
                    className='flex bg-[#FFE300] rounded-lg flex-col justify-center items-center p-6 pt-10'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <h2 className='pb-10 text-2xl'>Contact Form</h2>
                    <div className='flex flex-col space-y-2 lg:w-5/6 w-2/3'>
                        <Controller
                            name='name'
                            control={control}
                            defaultValue=''
                            rules={{ required: true, pattern: /^[A-Za-z\s]+$/ }}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    className={`bg-slate-200 p-1 ${errors.name ? 'border-red-500' : ''}`}
                                    type='text'
                                    placeholder='Name'
                                />
                            )}
                        />
                        {errors.name && (
                            <p className='text-red-500'>Name can only contain letters and spaces</p>
                        )}

                        <Controller
                            name='email'
                            control={control}
                            defaultValue=''
                            rules={{
                                required: true,
                                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                validate: (value) => value.includes('gmail.com'),
                            }}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    className={`bg-slate-200 p-1 ${errors.email ? 'border-red-500' : ''}`}
                                    type='email'
                                    placeholder='Email: xyz@gmail.com'
                                />
                            )}
                        />
                        {errors.email && <p className='text-red-500'>Invalid email format or not a Gmail address</p>}

                        <Controller
                            name='phoneNumber'
                            control={control}
                            defaultValue=''
                            rules={{ required: true, pattern: /^[0-9]{10}$/ }}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    className={`bg-slate-200 p-1 ${errors.phoneNumber ? 'border-red-500' : ''}`}
                                    type='tel'
                                    placeholder='Phone Number: 1234567890'
                                />
                            )}
                        />
                        {errors.phoneNumber && (
                            <p className='text-red-500'>10 Number Phone Number is necessary</p>
                        )}

                        <Controller
                            name='message'
                            control={control}
                            defaultValue=''
                            rules={{ required: true }}
                            render={({ field }) => (
                                <textarea
                                    {...field}
                                    className={`bg-slate-200 p-1 lg:pb-10 ${errors.message ? 'border-red-500' : ''
                                        }`}
                                    placeholder='Message: eg: get a quote for a product'
                                />
                            )}
                        />
                        {errors.message && <p className='text-red-500'>Message is required</p>}

                        <div className='p-2'></div>
                        <button
                            className='border-2 text-white border-[#FFE300] min-w-full mx-auto p-1 bg-black'
                            type='submit'
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <GoogleMap />
            <Footer />
        </>
    );
};

export default AirtableForm;
