import axios from "axios";

const client = axios.create({
    baseURL: "https://api.airtable.com/v0",
    headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
    },
});

export default client;
