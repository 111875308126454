import React from "react";
import product from "../../Assets/product.jpg";

const ImageBox = () => {
  return (
    <>
      <div className="w-5/6 md:w-[75%] container mx-auto mt-24 bg-white img-BoxShadow -mb-60 z-30">
        <div className="flex flex-col items-center justify-center md:flex-row group">
          <div className="w-full overflow-hidden md:w-3/5">
            <img src={product} alt="ganesh cargo" className="w-full h-full duration-700 group-hover:scale-110" />
          </div>
          <div className="w-4/5 px-2 py-5 text-left md:w-2/5 md:py-0 md:px-10 group">
            <h2 className="mt-2 text-2xl text-left md:mt-2">What We Are Providing</h2>
            <span className="inline-block w-20 h-1 my-2 duration-700 rounded md:my-5 group-hover:w-52 bg-yellowColor"></span>
            <p className=" text-left text-lg ">Discover our extensive selection of eco-friendly disposable products, from clamshell containers, bowls, plates, and trays to wooden cutlery, paper straws, and palm leaf items.</p>
            <p className=" text-left text-lg lg:mt-4 mt-0"> Our sustainable solutions cater to diverse needs, making any occasion environmentally conscious</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageBox;
