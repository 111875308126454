import React from "react";
import Block from "./Block";

const MultipleBlocks = () => {
    const blocks = [
        {
            title: "About Our Work",
            icon: "fa-briefcase",
            desc: "We provide globally import and export service to our Valuable Clients.",
        },
        {
            title: "About Company",
            icon: "fa-building",
            desc: "We are a dedicated provider of eco-conscious disposable items. Our products, crafted from palm leaves and other sustainable materials.",
        },
        {
            title: "Why Us?",
            icon: "fa-question",
            desc: "We are here to provide High quality products to our customer.",
        },
    ];
    return (
        <div className="container flex flex-col items-center justify-center mx-auto -mt-20 md:flex-row">
            {blocks.map((block, index) => (
                <Block title={block.title} icon={block.icon} key={index} index={index} desc={block.desc} color={index === 1 ? "white" : "yellowColor"} />
            ))}
        </div>
    );
};

export default MultipleBlocks;
