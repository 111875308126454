import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CertificateSection = ({ certificates }) => {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Display 3 certificates at a time
        slidesToScroll: 3, // Scroll 3 certificates at a time
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    useEffect(() => {
        sliderRef.current.slickPlay();
    }, []);

    return (
        <div className="container mx-auto p-4 lg:w-[900px] w-4/5 lg:h-[600] overflow-hidden lg:mb-5 mb-0">
            <div className="relative">
                <Slider {...settings} ref={sliderRef}>
                    {certificates.map((certificate, index) => (
                        <div key={index} className="px-4 py-6">
                            <div className="flex justify-center items-center">
                                <img src={certificate.image} alt={`Certificate ${index + 1}`} className="lg:h-52 max-h-min" />
                            </div>
                            {/* Add any additional content here */}
                        </div>
                    ))}
                </Slider>
                <button className="absolute top-1/2 -left-6 transform -translate-y-1/2 bg-[#FFE300] text-white px-2 py-2 rounded-md shadow-md opacity-60" onClick={goToPrev}>
                    <img src="https://img.icons8.com/ios-glyphs/30/ffffff/chevron-left.png" alt="left arrow" />
                </button>
                <button className="absolute top-1/2 -right-6 transform -translate-y-1/2 bg-[#FFE300] text-white px-2 py-2 rounded-md shadow-md opacity-60" onClick={goToNext}>
                    <img src="https://img.icons8.com/ios-glyphs/30/ffffff/chevron-right.png" alt="right arrow" />
                </button>
               
            </div>
        </div>
    );
};

export default CertificateSection;